import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
// import Header from "components/Header/Header.js";
import UnregisteredHeaderLinks from "components/Header/UnregisteredHeaderLinks";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import image from "assets/img/bg7.jpg";
import AuthApi from "../../api/auth";
import { useAuth } from "../../context/auth.context";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(loginPageStyle);

export default function LoginPage() {
  const history = useHistory();
  const { setUser } = useAuth();
  const { user } = useAuth();

  const [email, setEmail] = useState("test@appseed.us");
  const [password, setPassword] = useState("pass");
  const [error, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("Let's go");

  const classes = useStyles();

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      return history.push("/presentation-page");
    }

    if (email === "") {
      return setError("You must enter your email.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }
    try {
      let response = await AuthApi.Login({
        email,
        password,
      });
      if (response.data && response.data.success === false) {
        return setError(response.data.msg);
      }
      setButtonText("Logging in");
      return setProfile(response);
    } catch (err) {
      console.log(err);
      setButtonText("Let's go");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  const setProfile = (response) => {
    let user = { ...response.data.user };
    user.token = response.data.token;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    return history.push("/presentation-page");
  };

  return (
    <div>
      <UnregisteredHeaderLinks
        color="transparent"
        brandText="Material Kit PRO React"
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>
                      {"React Node Material Kit PRO"}
                    </h4>
                    <div className={classes.socialLine}>
                      <p style={{ textAlign: "center" }}>
                        {"Default login: test@appseed.us / pass"}
                      </p>
                    </div>
                  </CardHeader>
                  <p className={classes.description + " " + classes.textCenter}>
                    Or Be Classical
                  </p>
                  <CardBody signup>
                    {user && user.token ? (
                      <div>
                        <h4 style={{ textAlign: "center" }}>
                          You are already logged in
                        </h4>
                      </div>
                    ) : (
                      <>
                        <CustomInput
                          id="email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            defaultValue: `${email}`,
                            onChange: (event) => {
                              setEmail(event.target.value);
                              setError(undefined);
                            },
                            placeholder: "Email...",
                            type: "email",
                            startAdornment: (
                              <InputAdornment position="start">
                                <Email className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          id="pass"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            defaultValue: `${password}`,
                            onChange: (event) => {
                              setPassword(event.target.value);
                              setError(undefined);
                            },
                            placeholder: "Password",
                            type: "password",
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon className={classes.inputIconsColor}>
                                  lock_utline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: "off",
                          }}
                        />
                        <h5
                          style={{
                            fontSize: "1em",
                            color: "red",
                            textAlign: "center",
                            fontWeight: 400,
                            height: "25px",
                            transition: ".2s all",
                            opacity: error !== undefined ? 1 : 0,
                          }}
                        >
                          {error}
                        </h5>
                      </>
                    )}
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button
                      disabled={error ? true : false}
                      onClick={login}
                      simple
                      color="primary"
                      size="lg"
                    >
                      {buttonText}
                    </Button>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      rel="noreferrer"
                      href="https://appseed.us/apps/react"
                      target="_blank"
                      className={classes.block}
                    >
                      React AppS
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      rel="noreferrer"
                      href="https://appseed.us/support"
                      target="_blank"
                      className={classes.block}
                    >
                      Support
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy;
                <a
                  rel="noreferrer"
                  href="https://bit.ly/3fKQZaL"
                  target="_blank"
                >
                  Creative Tim
                </a>{" "}
                Coded by AppSeed.
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
