/* eslint-disable prettier/prettier */
import React from "react";
import { Redirect, Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

// import AuthLayout from "./layouts/Auth.js";
// import RtlLayout from "./layouts/RTL.js";
// import AdminLayout from "./layouts/Admin.js";
// import { ProtectedRoute } from "./ProtectedRoute";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import BlogPostPage from "views/BlogPostPage/BlogPostPage.js";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.js";
import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import EcommercePage from "views/EcommercePage/EcommercePage.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import PricingPage from "views/PricingPage/PricingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import ProductPage from "views/ProductPage/ProductPage.js";
import SectionsPage from "views/SectionsPage/SectionsPage.js";
import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.js";
import SignupPage from "views/SignupPage/SignupPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";

import { ProtectedRoute } from "./ProtectedRoute";

var hist = createBrowserHistory();

export const Routes = () => {
  return (
    <Router history={hist}>
      <Switch>
        <ProtectedRoute path="/about-us" component={AboutUsPage} />
        <ProtectedRoute path="/blog-post" component={BlogPostPage} />
        <ProtectedRoute path="/blog-posts" component={BlogPostsPage} />
        <ProtectedRoute path="/components" component={ComponentsPage} />
        <ProtectedRoute path="/contact-us" component={ContactUsPage} />
        <ProtectedRoute path="/ecommerce-page" component={EcommercePage} />
        <ProtectedRoute path="/landing-page" component={LandingPage} />
        <ProtectedRoute path="/pricing" component={PricingPage} />
        <ProtectedRoute path="/profile-page" component={ProfilePage} />
        <ProtectedRoute path="/product-page" component={ProductPage} />
        <ProtectedRoute path="/sections" component={SectionsPage} />
        <ProtectedRoute path="/shopping-cart-page" component={ShoppingCartPage} />
        <ProtectedRoute path="/error-page" component={ErrorPage} />
        <ProtectedRoute path="/presentation-page" component={PresentationPage} />
        <Route path="/login-page" component={LoginPage} />
        <Route path="/signup-page" component={SignupPage} />
        <Redirect from="/" to="/login-page" />
      </Switch>
    </Router>
  );
};
