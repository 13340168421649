import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "./context/auth.context";
import { useHistory } from "react-router-dom";

import SnackbarContent from "./components/Snackbar/SnackbarContent.js";

export const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  let { user } = useAuth();

  if (!user || !user.token || user.token === "") {
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <div style={{ position: "fixed", top: 0, zIndex: 9999, width: "100%" }}>
          <SnackbarContent
            message={
              <span>
                <b>INFO ALERT:</b> You have to be logged in first!
              </span>
            }
            close
            color="danger"
            icon="error_outline"
            callback={() => history.push("/login-page")}
          />
        </div>

        <div
          style={{
            filter: "blur(10px)",
            pointerEvents: "none",
          }}
        >
          <Route {...rest} />
        </div>
      </div>
    );
  }

  return <Route {...rest} />;
};
