import React from "react";

import { AuthProvider } from "./context/auth.context";
import { Routes } from "./Routes";

let user = localStorage.getItem("user");
user = JSON.parse(user);

const App = () => {
  return (
    <AuthProvider userData={user}>
      <Routes />
    </AuthProvider>
  );
};

export default App;
